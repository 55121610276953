


















































import Vue from 'vue';
import CreateMembershipModel from '../../../../../services/viewModel/resource/CreateMembershipRequestViewModel';

export default Vue.extend({
  props: {
    value: { type: undefined, default: true },
  },
  data: () => ({
    disableAddressIsDifferent: false,
    isPostalCodeSameAsMemberAddress: false,
  }),
  computed: {
    proceedDisabled(): boolean {
      const parsedCompanyName = this.innerValue.memberContact?.address?.companyName?.toLowerCase()?.replace(/\s/g, '');
      const parsedBusinessName = this.innerValue.membershipBusiness?.companyName?.toLowerCase()?.replace(/\s/g, '');

      if (this.innerValue.memberContact.address?.companyLegalFormId === this.$config.values['gs-business-legal-form-einzelunternehmen-lookup-id'] || !this.innerValue.memberContact.address?.isCompany) {
        return false;
      }

      return parsedCompanyName === parsedBusinessName;
    },
    languageItems(): {caption: string, id: string}[] {
      return [
        {
          caption: this.$t('page.createMembership.select.german'),
          id: 'DE',
        },
        {
          caption: this.$t('page.createMembership.select.french'),
          id: 'FR',
        },
        {
          caption: this.$t('page.createMembership.select.italian'),
          id: 'IT',
        },
      ];
    },
    innerValue: {
      get(): CreateMembershipModel {
        return this.value as any ?? {} as any;
      },
      set(value: CreateMembershipModel) {
        this.$emit('input', value);
      },
    },
  },
  mounted() {
    if (this.innerValue.memberBusinessContact.address?.postalCode === this.innerValue.memberContact.address?.postalCode) {
      this.disableAddressIsDifferent = false;
      this.isPostalCodeSameAsMemberAddress = false;
    } else {
      this.innerValue.memberBusinessContact.isDifferentFromOwner = true;
      this.disableAddressIsDifferent = true;
      this.isPostalCodeSameAsMemberAddress = true;
    }
  },
  methods: {
    clearValueInNextPage() {
      if (this.innerValue.membershipBusiness?.accommodation?.isAccommodation) {
        (this.innerValue.membershipBusiness.gastronomy.businessTypeId as any) = undefined;
        (this.innerValue.membershipBusiness.gastronomy.placesInHouse as any) = undefined;
        (this.innerValue.membershipBusiness.gastronomy.placesHall as any) = undefined;
        (this.innerValue.membershipBusiness.gastronomy.placesTerrace as any) = undefined;
        (this.innerValue.membershipBusiness.gastronomy.isWheelchairAccessible as any) = undefined;
        (this.innerValue.membershipBusiness.gastronomy.isRestRoomHandicappedAccessible as any) = undefined;
        (this.innerValue.membershipBusiness.gastronomy.hasSmokingRoom as any) = undefined;
      } else {
        (this.innerValue.membershipBusiness.accommodation.classificationRoomCount as any) = undefined;
        (this.innerValue.membershipBusiness.accommodation.classificationBedCount as any) = undefined;
        (this.innerValue.membershipBusiness.accommodation.classificationStarsCount as any) = undefined;
        (this.innerValue.membershipBusiness.accommodation.accommodationTypeId as any) = undefined;
        (this.innerValue.membershipBusiness.accommodation.isClassified as any) = undefined;
      }
    },
  },
});
